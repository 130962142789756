import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/accept-invite": [3],
		"/ap": [4],
		"/ap/[id]/[[tab]]": [5],
		"/audit": [6],
		"/erp-requests": [7],
		"/erp-syncs": [8],
		"/expenses/[id]/[[tab]]": [9],
		"/forgot-password": [10],
		"/gpt-prompts": [11],
		"/inbox": [12],
		"/llms": [13],
		"/login": [14],
		"/login/sso": [15],
		"/match": [16],
		"/onboarding/erp_connection": [17],
		"/onboarding/questionnaire": [18],
		"/order/[id]/[[tab]]": [19],
		"/organizations": [22],
		"/organization/new": [21],
		"/organization/[id]": [20],
		"/reporting": [23],
		"/reporting/reports": [24],
		"/reporting/usage": [25],
		"/request-format": [26],
		"/request-format/success": [27],
		"/reset-password": [28],
		"/search": [29],
		"/settings": [30],
		"/settings/account": [31],
		"/settings/auto-submit": [32],
		"/settings/inbox": [33],
		"/settings/teams": [34],
		"/settings/users": [35],
		"/training-examples": [36],
		"/validate-ftp": [37],
		"/verify-email": [38],
		"/verify-email/[token]": [39]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';